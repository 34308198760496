@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
  --color-bg: white;
  --transition: all 300ms ease;
  --container-width-lg: 84%;
  --container-width-md: 92%;
  --form-width: 50%;
  --radius-1: 0.3rem;
  --radius-2: 0.6rem;
  --radius-3: 1rem;
  --radius-4: 2rem;

}

* {
  margin:0;
  padding:0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Crimson Text", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.9rem;
  line-height: 1.6;
  color: black;
}

a {
  color: white;
  text-decoration: none;
}

/* .nav__container{
  background-color: black;
} */

.nav__logo{
  width: 3rem;
  display: block;
}

img{
  display: block;
  object-fit: cover;
  width: 80%;
}

.embla_latest {
  overflow: hidden;
  width: 100%;
}

.embla__container_latest {
  display: flex;
  transition: transform 0.05s ease-in-out;
}

.embla__slide_latest {
  position: relative;
  flex: 0 0 100%; /* Occupy the full viewport width */;
  margin-left: 50px;
  margin-right: 50px;
}

.carousel-btn {
  border: solid;
  border-width: medium;
}

.carousel-btn:hover {
  background-color: black !important;
  color: white;
}

.embla_category {
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 2rem;
  --slide-size: 50%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container_category {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide_category {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}

.truncated-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Prevent single-line truncation */
  -webkit-line-clamp: 3; /* Number of visible lines */
}

.detail-side {
  border: solid;
}

.li-cust {
  height:48px;
}